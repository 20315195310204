import React from 'react';
import { graphql } from 'gatsby';
import ProjectPreview from '../components/project-preview'
import Layout from '../components/layout';
import styled from 'styled-components'

const NoProject = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
`
export const query = graphql`
    {
        project: allContentfulProject(filter: {tags: {eq: "architecture"}}) {
            edges {
            node {
                title
                category
                shortDescription
                images {
                    fluid (maxWidth: 3080, quality: 100){
                      ...GatsbyContentfulFluid
                    }
                  }
                tags
                slug
            }
            }
      }
    }
`


const Architecture = ({ data }) => {
    const projects = data.project.edges;
    return (
        <Layout>
            <div>
                {projects.length ? projects.map(({ node: project }) => {
                    const title = project.title;
                    const category = project.category;
                    const slug = project.slug;
                    const tags = project.tags;
                    const description = project.description;
                    const imageData = project.images[0].fluid;
                    return (
                        <div>
                            <ProjectPreview
                                key={title}
                                title={title}
                                slug={slug}
                                description={description}
                                imageData={imageData}
                                tags={tags}
                                category={category}
                            />
                        </div>
                    )
                }) : <NoProject>No project with this tag <span role="img" aria-label="emoji">😎</span></NoProject>}
            </div>
        </Layout>
    )
}

export default Architecture;
